@import url(https://fonts.googleapis.com/css2?family=Muli:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{font-family:"Muli", sans-serif}::-webkit-scrollbar{width:6px;height:6px;background-color:#ebebeb;border-radius:20px}::-webkit-scrollbar-thumb{background-color:rgba(18,38,50,0.9);border-radius:20px}.paisa-text-color{color:#e7ebff}

.landing-background{background-color:#6379F0;height:100vh}.landing-background .landing-image{object-fit:contain}.landing-background .landing-text{width:153px}

