@import url("https://fonts.googleapis.com/css2?family=Muli:wght@500&display=swap");

* {
  font-family: "Muli", sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #ebebeb;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(18, 38, 50, 0.9);
  border-radius: 20px;
}

.paisa-text-color {
  color: #e7ebff;
}
