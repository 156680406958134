$blue: #6379F0;

.landing-background {
  background-color: $blue;
  height: 100vh;
  .landing-image {
    object-fit: contain;
  }
  .landing-text {
    width: 153px;
  }
}
